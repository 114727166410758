import React from 'react';
import ConsentConfirmedThankYou from './ConsentConfirmedThankYou';
import { useTranslation } from 'react-i18next';

function GenericError(props) {
    const [t] = useTranslation();
    return (
        <div>
            <ConsentConfirmedThankYou 
                lenderId='' 
                language='en'
                headerText={t("UhOh")} 
                bodyText={<p><b>{t("ErrorOnRequest")}</b></p>} 
                itemsClassColor="" 
                checkmarkIcon="./alert.svg"
                altCheckmarkIcon="success logo"/>
        </div>
    )
}

export default (GenericError);