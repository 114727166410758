import React from 'react';
import ConsentConfirmedThankYou from './ConsentConfirmedThankYou';
import { useTranslation } from 'react-i18next';

 function UrlExpired(props) {
    const [t] = useTranslation();
    const {lenderId, language} = props;
    return (
        <div>
            <ConsentConfirmedThankYou 
                lenderId={lenderId} 
                language={language}
                headerText={t("UhOh")} 
                bodyText={<p><b>{t("ExpiredLink")} </b></p>} 
                itemsClassColor="" 
                checkmarkIcon="./alert.svg"
                altCheckmarkIcon="success logo"/>
        </div>
    )
}

export default (UrlExpired);