import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { CircularProgress } from '@material-ui/core';
import './PdfDisplay.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function AllPages(props) {
  const [numPages, setNumPages] = useState(null);
  const [docHeight, setDocHeight] = useState(1920);
  const maxHeight = 3000;
  const minHeight = 600;
  const maxScreenSize = 1920;
  const minScreenSize = 0;
  const { pdf } = props;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  useEffect(() => {
    setDocHeight((maxHeight - minHeight) * (window.innerWidth - minScreenSize) / (maxScreenSize - minScreenSize) + minHeight);
  }, []);

  return (
    <Document  
      file={pdf}       
      options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={<div className='circularprocess'> <CircularProgress /></div>}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page height={docHeight} key={`page_${index + 1}`} pageNumber={index + 1} loading={<div className='circularprocess'> <CircularProgress /></div>}/>
      ))}
    </Document>
  );
}


