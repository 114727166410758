import { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap'; 
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import Modal from "react-modal";
import useAutoLogout from '../LogOut/UseAutoLogout';
import AllPagesPDFViewer from '../Pdf/AllPages';
import ConsentConfirmationPopup from '../Popup/ConsentConfirmationPopup';
import GenericError from '../StaticRedirects/GenericError';
import './ConsentForm.css';

Modal.setAppElement("#root");

function ConsentForm (props) {   
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [pdfDataUrl, setPdfDataUrl] = useState(''); 
    const [isLogoutConfirmationModalOpen, setIsLogoutConfirmationModalOpen] = useState(false);
    const [isAcceptConfirmationModalOpen, setIsAcceptConfirmationModalOpen] = useState(false);
    const [isAcceptCancelationModalOpen, setIsAcceptCancelationModalOpen] = useState(false);
    const [events, setEvents] = useState([]);
    const [logOutTime, setLogOutTime] = useState(120 * 5);
    const {id, isValid, lenderId, language} = props;
    const [t] = useTranslation();

    const toggleLogoutModal = () => {       
        setIsLogoutConfirmationModalOpen(!isLogoutConfirmationModalOpen);
    }

    useEffect(() => { 
        if(isLogoutConfirmationModalOpen) {
            setEvents([]);
        }
        else {
            setEvents([ "load","mousemove","mousedown","click","scroll","keypress"]);
        }

     }, [isLogoutConfirmationModalOpen])

    const toggleConfirmationModal = () => {   
        setIsAcceptConfirmationModalOpen(!isAcceptConfirmationModalOpen);
        setIsAcceptCancelationModalOpen(false);
    }

    const toggleCancelationModal = () => {   
        setIsAcceptCancelationModalOpen(!isAcceptCancelationModalOpen);
        setIsAcceptConfirmationModalOpen(false);
    }

    const timer = useAutoLogout(logOutTime, events);


    const logOut = useCallback(() => {
        setLogOutTime(-1);
        props.logOut(false);
    }, [props])


    useEffect(() => {
        if(!error) {
            const refreshList = () => {
                fetch(process.env.REACT_APP_API + `GetFile?requestId=${id}`, {
                    method: 'GET',
                    headers: {
                        responseType: "blob",
                        Authorization: window.sessionStorage.getItem("jwt")
                    }
                })
                .then(function(data) {                      
                    if(data.status === 200)
                    {
                        return data.blob();         
                    }
                    setError(error);
                    logOut();
                    setIsLoaded(true);
                    return; 
                }) 
                .then(data => {
                    setPdfDataUrl(getPdfDataUrl(data));
                    setIsLoaded(true);
                },
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                })
            };
            refreshList();
        }    
    }, [id, logOut, error]);

    const getPdfDataUrl = (data) => {
        var tempFile = new Blob([data], {type: 'application/pdf'});
        var objectUrl = URL.createObjectURL(tempFile) + 
        "#toolbar=0";
        return objectUrl;
    }


    const acceptConsentForm = () => {
        if(!error) {
            fetch(process.env.REACT_APP_API + `AcceptConsentForm?id=${id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem("jwt")
                }
            })
            .then(function(res) {     
                if(res.status === 200)
                {
                return res.json();         
                }
                setError(error);
                logOut();
                setIsLoaded(true);
            })  
            .then((result) => {
                setIsLoaded(true);
                if (result) {
                    props.acceptConsentForm();
                }
            },
                (error) => {
                setError(error);
                setIsLoaded(true);
            })      
        }
    }


    const declineConsentForm = () => {
        if(!error) {
            fetch(process.env.REACT_APP_API + `DeclineConsentForm?id=${id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': window.sessionStorage.getItem("jwt")
                }
            })
            .then(function(res) {     
                if(res.status === 200)
                {
                    return res.json();         
                }
                setError(error);
                logOut();
                setIsLoaded(true);
            })  
            .then((result) => {
                setIsLoaded(true);
                if (result) {
                    props.declineConsentForm();
                }
            },
            (error) => {
                setError(error);
                setIsLoaded(true);
            })       
        }
    }

    const addDefaultLogoSrc = (event) => {
        event.target.src = process.env.PUBLIC_URL + './dealertrack-logo-vector.svg';
        event.target.style.margin = "1rem";
    }; 

    if (error) {
        return <GenericError t={t}/> 
    } else if (!isLoaded) {
        return<div className="circularprocess"> <CircularProgress /> </div>;
    } else if (isValid) {
        if (timer === 0 && logOutTime !== -1) {
            return <div> {logOut()}</div>
        }
        else {
            return (       
                <div className="consent-form">                              
                    <div>
                        <ConsentConfirmationPopup 
                            isModalOpen={timer <= 120}
                            contentLabel="Timeout Confirmation"
                            onAfterOpen={toggleLogoutModal}
                            header={<div>{t("AreYouStillThere")}</div>}
                            content={<div>{t("InactiveLoggedOut")} <b>{timer}</b> {t("Seconds")}</div>}
                            leftButtonText={<div>{t("Continue")}</div>}
                            rightButtonText={<div>{t("Logout")}</div>}
                            leftButtonClick={toggleLogoutModal}
                            rightButtonClick={logOut} 
                            modalClassName = "modal__timeout"
                            overlayClassName = "modal__overlay-timeout"
                            closeModalButtonClassName = {undefined}
                            showCloseModalButton = {false}
                            headerClassName = "modal__header-left"
                            bodyClassName = "modal__content"
                            hrClassName = "modal__hr-line"
                            leftButtonTextClassName="btn-primary"
                            rightButtonTextClassName="btn-secondary"
                        /> 

                        <ConsentConfirmationPopup 
                            isModalOpen={isAcceptConfirmationModalOpen}
                            contentLabel="Accept Confirmation"
                            header={<div className="text-success"><b>{t("Wait!")}</b></div>}
                            content={<div>{t("AreYouSureConfirmationForm")} <b className="text-success">{t("Accept")}</b> {t("YourConsentForm")}?</div>} 
                            leftButtonText={<div>{t("IDoNotAccept")}</div>} 
                            rightButtonText={<div>{t("IAccept")}</div>} 
                            leftButtonClick={toggleConfirmationModal}
                            rightButtonClick={acceptConsentForm}
                            closeModalButtonClick={toggleConfirmationModal}
                            modalClassName = "modal__consent-confirmation"
                            overlayClassName = "modal__overlay--consent-confirmation"
                            closeModalButtonClassName = "close modal__close-button"
                            showCloseModalButton = {true}
                            headerClassName = "modal__header"
                            bodyClassName = "modal__content-margin"
                            hrClassName = "hidden"
                            leftButtonTextClassName="btn-secondary"
                            rightButtonTextClassName="btn-success"
                        /> 
 
                        <ConsentConfirmationPopup 
                            isModalOpen={isAcceptCancelationModalOpen}
                            contentLabel="Decline Confirmation"            
                            header={<div className="text-danger-light"><b>{t("Wait!")}</b></div>}
                            content={<div>{t("AreYouSureConfirmationForm")} <b className="text-danger-light">{t("Decline")}</b> {t("YourConsentForm")}?</div>} 
                            leftButtonText={<div>{t("IDoNotDecline")}</div>} 
                            rightButtonText={<div>{t("IDecline")}</div>} 
                            leftButtonClick={toggleCancelationModal}
                            rightButtonClick={declineConsentForm}
                            closeModalButtonClick={toggleCancelationModal}                      
                            modalClassName = "modal__consent-confirmation"
                            overlayClassName = "modal__overlay--consent-confirmation"
                            closeModalButtonClassName = "close modal__close-button"
                            showCloseModalButton = {true}
                            headerClassName = "modal__header"
                            bodyClassName = "modal__content-margin"
                            hrClassName = "hidden"
                            leftButtonTextClassName="btn-danger-light btn-danger"
                            rightButtonTextClassName="btn-danger"
                        /> 
                    </div> 

                    <Container className="container">
                        <Row>
                            
                            <Col xs={12} sm={12} md={2} lg={2} >
                                <img id="form-logo" onError={addDefaultLogoSrc} className="form-logo sticky-top"  src={`${process.env.REACT_APP_IMAGES}${lenderId.toUpperCase()}/${lenderId.toUpperCase()}logo_update${language.toUpperCase().replace("-CA", "")}.png`} alt="logo"/>           
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={8}> 
                                <AllPagesPDFViewer className="all-page-container" pdf={pdfDataUrl} />
                            </Col>                          
                        </Row> 
                        <Row>
                            <Col className="m-3 d-flex justify-content-center"> 
                                <button id="decline-btn" type="button" className="btn btn-danger button" onClick={toggleCancelationModal}>{<div>{t("Decline")}</div>} </button>  
                                <button id="concent-btn" type="button" className="btn btn-success button" onClick={toggleConfirmationModal}>{<div>{t("Accept")}</div>} </button>
                            </Col>              
                        </Row>          
                    </Container>
                </div>
            )
        }
    }
    else {     
        return <GenericError t={t}/> 
    };      
}
export default (ConsentForm);